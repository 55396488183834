@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); 

body {
  margin: 0;
  font-family: 'Poppins' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.time {
  margin:0px 3px;
  border:1px solid rgb(230,230,230);
  border-radius: 10px;
  padding:0px 5px

}
.printer {
  padding:30px;
}
code {
  font-family: 'Poppins' !important;
}
select:focus{
  outline:none !important;
}
select>option {
  padding:20px !important;
  line-height: 31px !important;
}
select.option1:hover{
  color: red !important;
}
::placeholder{
  color:rgb(150,150,150) !important;
  opacity: 1 !important;
  font-size: 14px;
}
.fdc{
  flex-direction: column;
}
.fdr{
  flex-direction: row;
}
.df {
  display: flex;
}
.dif {
  display: inline-flex;
}
.jc {
  justify-content: center;
}
.jse {
  justify-content: space-evenly;
}
.jsb {
  justify-content: space-between;
}
.jfe{
  justify-content: flex-end;
}
.jsa {
  justify-content: space-around;
}
.ac {
  align-items: center;
}
.abl {
  align-items:baseline;
}
.afs {
  align-items:flex-start;
}
.afe{
  align-items: flex-end;
}
.ast {
  align-items: stretch;
}
 .fg{
   flex-grow: 1;
 }
.circle {
  border-radius: 50%;
}
/* .messageToast {
  display: flex;
  justify-content: flex-end;
} */

.siteLink:hover{
text-decoration: underline;
font-weight: bold;
}
.alertMessage {

  max-width: 280px;
  float:right;
}
.messageCont {
  background-color: red !important;
}
.ellipsis {
  width:100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left:0px;
}

.searchBtn {
  color: #e89607 !important;
}

.searchBtn:hover {
  background-color: #C3073F;
  color:white !important;
  cursor:pointer;
}

.after-banner-container{
  background:linear-gradient(transparent,transparent,transparent,rgba(13,0,0,0.1),rgba(13,0,0,0.4),rgba(13,0,0,0.7),rgba(13,0,0,0.9),rgb(17,0,0));
  border-width: 0px;
  bottom:0px;
  z-index: 10;
  position: absolute;
  min-height:600px;
  height:auto;
  width:100vw;
}

.swal_confirm {
  background: red;
  color:white;
  border:none;
  border-radius: 10px;
  padding:10px;
  margin-right:10px;
}

.swal_cancel {
  background: white;
  color:black;
  border:1px solid black;
  border-radius: 10px;
  padding:10px;
}

@media  screen and (min-width:599px){
.lessonCard {
  border:1px solid rgb(230,230,230);
}
  }

/* sizes 600px , 370px */